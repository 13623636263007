.button-hollow {
  @mixin button;

  background-color: transparent;
  border: .0625rem solid $color-default;
  color: $color-default;
  transition: background-color .3s ease-in-out;
  z-index: $index-front;

  &:hover,
  &:focus {
    background: $color-default;
    color: $color-neutral;
  }

  &.disabled,
  &[disabled] {
    border-color: $color-default-light;
    color: $color-default-light;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $color-default-light;
    }
  }
}

@each $color in primary, secondary, danger {

  .button-hollow-$(color) {
    border-color: $color-$(color);
    color: $color-$(color);

    &:hover,
    &:focus {
      background-color: $color-$(color);
    }
  }
}
