.glyphs {
  @mixin clearfix;

  .glyph-container {
    background-color: $color-default-lighter;
    cursor: pointer;
    font-size: .8rem;
    height: 85px;
    margin-bottom: 1rem;
    padding: 1rem .7rem;
    text-align: center;
    transition: all .1s ease-in-out;

    &:hover {
      background-color: $color-primary;
      color: $color-neutral;
    }

    .glyph {
      font-size: 1.3rem;
      margin-bottom: .31rem;
    }
  }
}
