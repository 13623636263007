strong,
b,
.strong {
  font-weight: bold;
}

em,
i,
.italic {
  font-style: italic;
}

u,
.underlined {
  text-decoration: underline;
}
