@define-mixin heading-margin {
  margin-bottom: .625rem;
}

@define-mixin heading-font {
  font-family: inherit;
  font-weight: 500;
}

h1,
.heading-1 {
  font-size: 2.5rem;
  @mixin heading-font;
  @mixin heading-margin;
}

h2,
.heading-2 {
  font-size: 1.875rem;
  @mixin heading-font;
  @mixin heading-margin;
}

h3,
.heading-3 {
  font-size: 1.5rem;
  @mixin heading-font;
  @mixin heading-margin;
}

h4,
.heading-4 {
  font-size: 1.25rem;
  @mixin heading-font;
  @mixin heading-margin;
}

h5,
.heading-5 {
  font-size: 1rem;
  @mixin heading-font;
  @mixin heading-margin;
}

h6,
.heading-6 {
  font-family: inherit;
  font-size: .875rem;
  font-weight: 300;
  @mixin heading-margin;
}
