.tooltip {
  background: $color-neutral;
  border-radius: .25rem;
  box-shadow: 0 0 3px 0 color($color-default-darker alpha(.3));
  color: $color-default-dark;
  font-size: .8rem;
  opacity: 0;
  padding: .5rem;
  position: absolute;
  transition: opacity .3s ease-in-out, visibility .6s;
  visibility: hidden;
  z-index: $index-ahead;

  &::after {
    border-style: solid;
    border-width: .5rem;
    content: "";
    height: 0;
    margin: .5rem;
    position: absolute;
    width: 0;
  }

  &[x-placement^="top"] {
    margin-bottom: .75rem;
  }

  &[x-placement^="top"]::after {
    border-bottom-width: 0;
    border-color: $color-primary transparent transparent transparent;
    bottom: -.5rem;
    left: calc(50% - 14px);
    margin-bottom: 0;
    margin-top: 0;
  }

  &[x-placement^="bottom"] {
    margin-top: .75rem;
  }

  &[x-placement^="bottom"]::after {
    border-color: transparent transparent $color-primary transparent;
    border-top-width: 0;
    left: calc(50% - 14px);
    margin-bottom: 0;
    margin-top: 0;
    top: -.5rem;
  }

  &[x-placement^="right"] {
    margin-left: .75rem;
  }

  &[x-placement^="right"]::after {
    border-color: transparent $color-primary transparent transparent;
    border-width: .5rem .5rem .5rem 0;
    left: -.5rem;
    margin-left: 0;
    margin-right: 0;
    top: calc(10% - .5rem);
  }

  &[x-placement^="left"] {
    margin-right: .75rem;
  }

  &[x-placement^="left"]::after {
    border-color: transparent transparent transparent $color-primary;
    border-width: .5rem 0 .5rem .5rem;
    margin-left: 0;
    margin-right: 0;
    right: -.5rem;
    top: calc(10% - .5rem);
  }
}
