@font-face {
    font-family: Glyphs;
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/Glyphs.woff") format("woff")
}
.glyph {
    font-family: Glyphs;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none
}
@define-mixin arrow-left {
    content: "\EA01"
}
.glyph-arrow-left::before {
    content: "\EA01"
}
@define-mixin arrow-right {
    content: "\EA02"
}
.glyph-arrow-right::before {
    content: "\EA02"
}
@define-mixin calendar {
    content: "\EA03"
}
.glyph-calendar::before {
    content: "\EA03"
}
@define-mixin camera {
    content: "\EA04"
}
.glyph-camera::before {
    content: "\EA04"
}
@define-mixin cart {
    content: "\EA05"
}
.glyph-cart::before {
    content: "\EA05"
}
@define-mixin chat-balloon {
    content: "\EA06"
}
.glyph-chat-balloon::before {
    content: "\EA06"
}
@define-mixin checked {
    content: "\EA07"
}
.glyph-checked::before {
    content: "\EA07"
}
@define-mixin clock {
    content: "\EA08"
}
.glyph-clock::before {
    content: "\EA08"
}
@define-mixin clone {
    content: "\EA09"
}
.glyph-clone::before {
    content: "\EA09"
}
@define-mixin dollar-sign {
    content: "\EA0A"
}
.glyph-dollar-sign::before {
    content: "\EA0A"
}
@define-mixin double-arrow-down {
    content: "\EA0B"
}
.glyph-double-arrow-down::before {
    content: "\EA0B"
}
@define-mixin double-arrow-left {
    content: "\EA0C"
}
.glyph-double-arrow-left::before {
    content: "\EA0C"
}
@define-mixin double-arrow-right {
    content: "\EA0D"
}
.glyph-double-arrow-right::before {
    content: "\EA0D"
}
@define-mixin double-arrow-up {
    content: "\EA0E"
}
.glyph-double-arrow-up::before {
    content: "\EA0E"
}
@define-mixin envelope {
    content: "\EA0F"
}
.glyph-envelope::before {
    content: "\EA0F"
}
@define-mixin exclamation-mark {
    content: "\EA10"
}
.glyph-exclamation-mark::before {
    content: "\EA10"
}
@define-mixin external {
    content: "\EA11"
}
.glyph-external::before {
    content: "\EA11"
}
@define-mixin eye {
    content: "\EA12"
}
.glyph-eye::before {
    content: "\EA12"
}
@define-mixin heart {
    content: "\EA13"
}
.glyph-heart::before {
    content: "\EA13"
}
@define-mixin location {
    content: "\EA14"
}
.glyph-location::before {
    content: "\EA14"
}
@define-mixin lock {
    content: "\EA15"
}
.glyph-lock::before {
    content: "\EA15"
}
@define-mixin magnifier {
    content: "\EA16"
}
.glyph-magnifier::before {
    content: "\EA16"
}
@define-mixin order {
    content: "\EA17"
}
.glyph-order::before {
    content: "\EA17"
}
@define-mixin pencil {
    content: "\EA18"
}
.glyph-pencil::before {
    content: "\EA18"
}
@define-mixin printer {
    content: "\EA19"
}
.glyph-printer::before {
    content: "\EA19"
}
@define-mixin projects {
    content: "\EA1A"
}
.glyph-projects::before {
    content: "\EA1A"
}
@define-mixin question-mark {
    content: "\EA1B"
}
.glyph-question-mark::before {
    content: "\EA1B"
}
@define-mixin refresh {
    content: "\EA1C"
}
.glyph-refresh::before {
    content: "\EA1C"
}
@define-mixin settings {
    content: "\EA1D"
}
.glyph-settings::before {
    content: "\EA1D"
}
@define-mixin signout {
    content: "\EA1E"
}
.glyph-signout::before {
    content: "\EA1E"
}
@define-mixin silhouette {
    content: "\EA1F"
}
.glyph-silhouette::before {
    content: "\EA1F"
}
@define-mixin tag {
    content: "\EA20"
}
.glyph-tag::before {
    content: "\EA20"
}
@define-mixin tools {
    content: "\EA21"
}
.glyph-tools::before {
    content: "\EA21"
}
@define-mixin trash-can {
    content: "\EA22"
}
.glyph-trash-can::before {
    content: "\EA22"
}
@define-mixin unlock {
    content: "\EA23"
}
.glyph-unlock::before {
    content: "\EA23"
}
@define-mixin verified-price {
    content: "\EA24"
}
.glyph-verified-price::before {
    content: "\EA24"
}
@define-mixin x {
    content: "\EA25"
}
.glyph-x::before {
    content: "\EA25"
}
