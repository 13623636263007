@define-mixin disable-shadow {

  &::after,
  &::before {
    content: none;
  }

  &:hover,
  &:focus {
    transform: none;
  }
}
