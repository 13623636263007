.border {
  border: .0625rem solid $color-primary-light;

  &-hover {
    transition: border-color .3s ease-in-out;

    &:hover {
      border-color: $color-primary;
    }
  }

  &-active {
    border-color: $color-secondary;

    transition: none;

    &:hover {
      border-color: $color-secondary;
    }
  }

  &-disabled {
    opacity: .4;
    transition: none;

    &:hover {
      border-color: $color-primary-light;
    }
  }
}
