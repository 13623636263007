.banner {
  background-color: $color-primary;
  height: 18.75rem;
  text-align: center;

  .title {
    color: $color-neutral;
    font-size: 1.875em;
    line-height: 1.3em;
    @mixin clearfix;
    margin-bottom: 2rem;
    max-width: 43.75;
  }

  .btn {
    border: .06rem solid $color-neutral;
    border-radius: .18rem;
    color: $color-neutral;
    display: inline-block;
    font-size: 1.4em;
    font-weight: 200;
    padding: .625em 1.25em;

    &:hover {
      background-color: $color-neutral;
      color: $color-primary;
    }
  }
}

@media (--small) {

  .banner {
    padding-top: 3em;
    text-align: left;

    .button {
      float: left;
    }
  }
}
