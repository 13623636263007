.callout {
  border-left: .25rem solid $color-primary;
  margin: 1.5rem 0;
  padding: .3rem 1rem;

  p {
    margin-bottom: 0;
    margin-top: .3rem;
  }
}

.callout-title {
  color: $color-primary;
  font-size: 1.5rem;
}
