.content {

  & > *:first-child {
    margin-top: 0;
  }

  & > h2 {
    margin-top: 3.75rem;
  }

  p code {
    border-bottom: .06rem solid $color-default-light;
    border-radius: .12rem;
    color: $color-primary;
    display: inline-block;
  }

  .example-item {
    margin-bottom: .62rem;
    margin-top: .62rem;
  }

  a:not(.button):not(.pagination-item),
  .link {

    &:hover {
      text-decoration: underline;
    }
  }
}
