.badge {
  background-color: $color-default;
  border-radius: .18rem;
  color: $color-neutral;
  display: inline-block;
  font-size: .87rem;
  padding-bottom: .12rem;
  padding-left: .31rem;
  padding-right: .31rem;
  padding-top: .0625rem;

  &-danger {
    background-color: $color-danger;
  }

  &-neutral {
    background-color: $color-neutral;
    color: $color-text;
  }

  &-primary {
    background-color: $color-primary;
  }

  &-secondary {
    background-color: $color-secondary;
  }
}
