.steps-stage {
  margin: 0 auto;
  min-height: 5rem;
  position: relative;
  text-align: center;
  width: 100%;

  &::before,
  &::after {
    background-color: $color-default-light;
    border-bottom: .25rem solid $color-default-light;
    border-top: .25rem solid $color-default-light;
    box-sizing: content-box;
    content: "";
    height: .125rem;
    position: absolute;
    top: 1.25rem;
    transform: translateY(-50%);
    width: 50%;
    z-index: $index-rear;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  &:first-of-type::before,
  &:last-of-type::after {
    display: none;
  }
}
