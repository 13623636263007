@import "text/heading";
@import "text/paragraph";
@import "text/style";
@import "text/alignment";
@import "text/transform";
@import "text/link";

html {
  font-size: $default-font-size;
  -webkit-font-smoothing: antialiased;
}

body {
  color: $color-text;
  font-family: $default-font-family;
  margin: 0;
}

hr {
  background-color: $color-default-light;
  border: 0;
  height: .0625rem;
  width: 100%;
}
