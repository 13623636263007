.modal-content {
  background-color: $color-neutral;
  border-radius: .25rem;
  box-shadow: 0 0 .3rem rgba(0, 0, 0, .5);
  display: none;
  left: 50%;
  margin-left: -47.5%;
  max-height: 90%;
  opacity: 0;
  overflow: auto;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  position: relative;
  transform: scale(1.3);
  transition: transform .3s ease, opacity .3s ease;
  vertical-align: middle;
  width: 95%;
}

.modal-content-enter {
  display: inline-block;
}

.modal-content-show {
  opacity: 1;
  transform: scale(1);
}

.modal-content-leave {
  opacity: 0;
  transform: scale(1.3);
}

.modal-content-hide {
  display: none;
}

@media (--medium) {

  .modal-content-lg {
    margin-left: -37.5%;
    width: 75%;
  }

  .modal-content-md {
    margin-left: -32.5%;
    width: 65%;
  }

  .modal-content-sm {
    margin-left: -27.5%;
    width: 55%;
  }
}

@media (--large) {

  .modal-content-lg {
    margin-left: -30%;
    width: 60%;
  }

  .modal-content-md {
    margin-left: -25%;
    width: 50%;
  }

  .modal-content-sm {
    margin-left: -20%;
    width: 40%;
  }
}
