.button {
  @mixin button;

  background-color: $color-default;
  border: 0;
  color: $color-neutral;
  transition: transform .3s ease-in-out;

  &::before,
  &::after {
    border-radius: .25rem;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity .3s ease-in-out;
    width: 100%;
    z-index: $index-rear;
  }

  &::before {
    box-shadow: 0 .12rem .12rem 0 rgba(0, 0, 0, .16), 0 .12rem .5rem 0 rgba(0, 0, 0, .12);
  }

  &::after {
    box-shadow: 0 1.25rem 1.25rem -.62rem rgba(0, 0, 0, .2), 0 .25rem .81rem 0 rgba(0, 0, 0, .2);
    opacity: 0;
  }

  &:hover,
  &:focus {
    transform: translateY(-.0625rem);
  }

  &:hover {

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }

  &.no-shadow {
    @mixin disable-shadow;
  }

  &.disabled,
  &[disabled] {
    background-color: $color-default-light;
    box-shadow: none;
    color: $color-neutral;
    cursor: not-allowed;

    @mixin disable-shadow;
  }

  &.disabled {
    pointer-events: none;
  }
}

@each $color in primary, secondary, danger {

  .button-$(color) {
    background-color: $color-$(color);
  }
}

.button-neutral {
  background-color: $color-neutral;
  color: $color-text;
}

.button-text {
  background-color: transparent;
  color: $color-primary;
  padding: 0;
  transition: none;

  @mixin disable-shadow;

  &[disabled],
  &.disabled {
    background-color: transparent;
    color: $color-default-light;
  }

  &:hover {
    text-decoration: none;
  }
}

.button-full {
  width: 100%;
}
