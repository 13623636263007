.notification {
  background-color: $color-default-lighter;
  border-color: $color-default-dark;
  border-radius: .25rem;
  border-style: solid;
  border-width: 1px;
  color: $color-text;
  padding-bottom: .75rem;
  padding-left: .6rem;
  padding-right: 2rem;
  padding-top: .75rem;
  position: relative;
  transition: opacity .3s ease-in-out;

  &-primary {
    background-color: $color-primary-lighter;
    border-color: $color-secondary;
    color: $color-primary;
  }

  &-warning {
    background-color: $color-warning-light;
    border-color: $color-warning;
    color: $color-text;
  }

  &-enter {
    display: block;
    opacity: 0;
  }

  &-leave {
    opacity: 0;
  }

  &-hide {
    display: none;
  }

  &-show {
    opacity: 1;
  }
}
