.docs {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;

  [data-copy] {
    border-radius: .1em;
    font-family: $default-font-family;
    font-size: .8em;
    padding: .2rem .3rem;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      transform: translateY(0);
    }
  }

  [data-clipboard-text] {
    overflow: hidden;
    position: relative;

    &::before {
      background-color: $color-default-dark;
      color: $color-neutral;
      content: "copied";
      font-weight: bold;
      left: 0;
      padding: .3rem;
      position: absolute;
      top: 0;
      transform: translateY(-100%);
      transition: transform .3s ease-in-out;
    }

    &.copied::before {
      transform: none;
    }
  }

  .example {

    &-code{
      border-bottom: 0;
      border-radius: .18rem .18rem 0 0;
    }

    &-container {
      margin: 0 auto;
      transition-duration: 1s;
      transition-property: width, margin;
      width: 70%;

      &--fluid {
        margin: auto;
        transition-duration: 1s;
        transition-property: width, margin;
        width: 100%;
      }
    }

    & + pre {
      border-radius: 0 0 .18rem .18rem;
      margin-top: 0;
    }

    .notification {
      margin-bottom: 0;
    }
  }

  pre {
    position: relative;
  }

  code[class*="language-"],
  pre[class*="language-"] {
    font-size: .8rem;
  }

  table:not(.table) {
    color: $color-default-dark;
    width: 100%;

    td,
    th{
      padding: .72rem .62rem;
    }

    thead {
      background-color: $color-primary;
      color: $color-neutral;
    }

    tbody {

      code {
        border-bottom: .06rem solid $color-default-light;
        border-radius: .12rem;
        color: $color-primary;
        display: inline-block;
      }

      tr {

        &:nth-child(even) {
          background-color: $color-primary-lighter;
        }
      }
    }
  }

  ul {
    display: inline-block;
    list-style: none;
    margin-top: 0;
    padding-left: 10px;

    a {
      display: block;
      margin-bottom: 0;
      padding-bottom: 2px;
      padding-top: 2px;

      &:hover {
        color: $color-primary-dark;
        text-decoration: none;
      }
    }
  }

  .hide {
    display: none;
  }

  .notification {
    margin-bottom: .6rem;
  }
}
