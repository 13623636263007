.example {
  border: .06rem solid $color-default-light;
  border-radius: .18rem;
  min-height: 3.56rem;
  padding: .93rem 1.25rem;

  h1,
  .heading-1,
  h2,
  .heading-2,
  h3,
  .heading-3,
  h4,
  .heading-4,
  h5,
  .heading-5,
  h6,
  .heading-6 {
    margin: .62rem 0;
  }

  .button {
    margin-bottom: .62rem;
    margin-right: .93rem;

    &:only-child,
    &:nth-last-child(4),
    &:nth-last-child(4) ~ .button {
      margin-bottom: 0;
    }
  }

  .glyph-container .glyph {
    font-size: 2rem;
  }

  > .price-tag {
    max-width: 8.75rem;
  }

  .thumbnail {
    width: 13.8rem;

    .button {
      margin-right: 0;
      width: 100%;
    }
  }

  .panel {
    width: 165px;

    &-large,
    &-wide {
      width: 335px;
    }
  }

  div[class*="col-"] {
    color: $color-neutral;
    margin-bottom: 10px;
    text-align: center;

    div {
      background-color: $color-primary;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }
}

@media (--small) {

  .example {

    .button {
      margin-bottom: 0;
    }
  }
}
