.palette {
  @mixin clearfix;

  .color {
    box-shadow: .06rem .06rem .18rem rgba(0, 0, 0, .2);
    cursor: pointer;
    font-size: .9rem;
    font-weight: bold;
    margin-bottom: 1rem;
    overflow: hidden;
    position: relative;
    text-align: center;

    &::after {
      display: inline-block;
      padding-bottom: 1.3rem;
      padding-top: 1.3rem;
      transition: transform .3s ease-in-out;
      width: 100%;
    }
  }

  .color-primary {
    background-color: $color-primary;

    &::after {
      content: "color-primary: $(color-primary)";
    }
  }

  .color-primary-dark {
    background-color: $color-primary-dark;
    color: $color-neutral;

    &::after {
      content: "color-primary-dark: $(color-primary-dark)";
    }
  }

  .color-primary-light {
    background-color: $color-primary-light;

    &::after {
      content: "color-primary-light: $(color-primary-light)";
    }
  }

  .color-primary-lighter {
    background-color: $color-primary-lighter;

    &::after {
      content: "color-primary-lighter: $(color-primary-lighter)";
    }
  }

  .color-secondary {
    background-color: $color-secondary;

    &::after {
      content: "color-secondary: $(color-secondary)";
    }
  }

  .color-default {
    background-color: $color-default;
    color: $color-neutral;

    &::after {
      content: "color-default: $(color-default)";
    }
  }

  .color-default-dark {
    background-color: $color-default-dark;
    color: $color-neutral;

    &::after {
      content: "color-default-dark: $(color-default-dark)";
    }
  }

  .color-default-darker {
    background-color: $color-default-darker;
    color: $color-neutral;

    &::after {
      content: "color-default-darker: $(color-default-darker)";
    }
  }

  .color-default-light {
    background-color: $color-default-light;

    &::after {
      content: "color-default-light: $(color-default-light)";
    }
  }

  .color-default-lighter {
    background-color: $color-default-lighter;

    &::after {
      content: "color-default-lighter: $(color-default-lighter)";
    }
  }

  .color-neutral {
    background-color: $color-neutral;

    &::after {
      content: "color-neutral: $(color-neutral)";
    }
  }

  .color-black {
    background-color: $color-black;
    color: $color-neutral;

    &::after {
      content: "color-black: $(color-black)";
    }
  }

  .color-text {
    background-color: $color-text;
    color: $color-neutral;

    &::after {
      content: "color-text: $(color-text)";
    }
  }

  .color-warning {
    background-color: $color-warning;

    &::after {
      content: "color-warning: $(color-warning)";
    }
  }

  .color-warning-light {
    background-color: $color-warning-light;

    &::after {
      content: "color-warning-light: $(color-warning-light)";
    }
  }

  .color-danger {
    background-color: $color-danger;
    color: $color-neutral;

    &::after {
      content: "color-danger: $(color-danger)";
    }
  }

  .color-success {
    background-color: $color-success;
    color: $color-neutral;

    &::after {
      content: "color-success: $(color-success)";
    }
  }
}
