.pagination-arrow {

  + .pagination-arrow {
    margin-left: 0;
  }

  &:hover {
    background-color: transparent;
    color: $color-primary-dark;
  }

  .glyph {
    vertical-align: text-top;
  }
}
