p,
.paragraph {
  font-size: .875rem;
  line-height: 1.5rem;
  margin-bottom: .875rem;
  margin-top: .875rem;
}

.lead {
  font-size: 1.25rem;
  line-height: 1.7rem;
}
